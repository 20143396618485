import { Component, Input, OnInit } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { ROLES } from '../../../globals';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  templateUrl: './one-column.layout.html',
  // template: `
  //   <nb-layout windowMode>
  //     <nb-layout-header fixed>
  //       <ngx-header></ngx-header>
  //     </nb-layout-header>

  //     <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
  //       <ng-content select="nb-menu"></ng-content>
  //     </nb-sidebar>

  //     <nb-layout-column>
  //       <ng-content select="router-outlet"></ng-content>
  //     </nb-layout-column>

  //     <nb-layout-footer fixed>
  //       <ngx-footer></ngx-footer>
  //     </nb-layout-footer>
  //   </nb-layout>
  // `,
})
export class OneColumnLayoutComponent implements OnInit {

  expanded: boolean;
  expandedSecond: boolean;
  expandedThird: boolean;
  // ROLE & PERMISSION
  userType = ROLES;
  permission;
  public role = localStorage.getItem('sidebarOption');

  constructor(
  ) { }

  ngOnInit() {
    this.permission = this.userType[this.role];
    // console.log(this.permission);
    this.expanded = false;
    // console.log(this.items);
  }

  onToggleSubMenu(event) {
    this.expanded = !this.expanded;
    if (this.expanded) {
      this.expandedSecond = false;
      this.expandedThird = false;
    }
  }

  onToggleSubMenu2(event) {
    this.expandedSecond = !this.expandedSecond;
  }

  onToggleSubMenu3(event) {
    this.expandedThird = !this.expandedThird;
  }
}
